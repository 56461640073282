//import $ from 'imports/jquery'
import siteCommon from 'site/site-common'

import { gsap, CSSPlugin, ScrollToPlugin } from 'imports/gsap/all'
import { Power4 } from 'imports/gsap'

import Swiper from 'swiper'
import { Navigation, Grid } from 'swiper/modules'

// lottie is slow to compile each time, so i am including it globally
import { DotLottie } from '@lottiefiles/dotlottie-web';

gsap.registerPlugin(ScrollToPlugin);
let global = {
    modalPopupInOpen: false,
    scrollContent: null,
    stickyOuter: null,
    stickySizer: 0,
    window: null,
    pageLoadContentScrollPosition: null,
    init: function () {
        $('.jump').on('click',global.jump)

        // Handle scrolling on page load if URL contains a hash
        if (window.location.hash) {
            const targetId = window.location.hash.substring(1); // Get the ID from the hash
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                gsap.to(window, {
                    duration: 0.75,
                    ease: Power4.easeInOut,
                    scrollTo: { y: targetElement, offsetY:0 }
                });
            }
        }

        global.lottieInit()
        global.modalPopupInit()
        global.initPromoSlides()
        global.initShowSticky()
    },
    ////////////////////////////////////////////////////////////////////////////////////

    jump: function() {
        let tid = '#' + $(this).attr('data-jump')
        gsap.to(window, {duration: 0.75, ease: Power4.easeInOut, scrollTo: {y: tid, offsetY: 0}});
    },

    initPromoSlides: function() {
        let elem = document.querySelector('#sponsors-hook.larger-quantity .swiper')
        if (elem) {
            let promoSwiper = new Swiper(elem, {
                modules: [Navigation, Grid],
                init: false,
                loop: true,
                spaceBetween: 50,
                slidesPerView: 2,
                slidesPerGroup: 1,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: ".block-sponsors .btn-arrow-right",
                    prevEl: ".block-sponsors .btn-arrow-left",
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 0,
                        grid: {
                            rows: 3,
                        },
                    },
                    900: {
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                        spaceBetween: 50,
                        grid: {
                            rows: 1,
                        },
                    }
                }
            })
            if (promoSwiper) {
                promoSwiper.init()
            }
        }

    },


    lottieInit() {
        // lottie.loadAnimation({
        //     container: document.querySelector('.lottie-circle'), // Canvas container
        //     renderer: 'canvas',
        //     loop: true,
        //     autoplay: true,
        //     path: lottieCircle,
        //     assetsPath: null,
        //     //assetsPath: finalCdnPath  + 'json/'
        //     //assetsPath: ''
        // });

        if (typeof DotLottie !== 'undefined') {
            if (document.querySelector('.lottie-circle')) {
                const circles = new DotLottie({
                    autoplay: true,
                    loop: true,
                    canvas: document.querySelector('.lottie-circle'),
                    src: lottieCircle
                });
            }
            if (document.querySelector('.lottie-square')) {
                const squares = new DotLottie({
                    autoplay: true,
                    loop: true,
                    canvas: document.querySelector('.lottie-square'),
                    src: lottieSquare
                });
            }

        }
    },


    /*
    lottieInit() {
        console.log('window', window, lottieCircle, lottieSquare)
        const circles = lottie.loadAnimation({
            container: document.querySelector('.lottie-circle'),
            autoplay: true,
            loop: true,
            renderer: 'svg',
            path: lottieCircle
        });

        const squares = lottie.loadAnimation({
            container: document.querySelector('.lottie-square'),
            autoplay: true,
            loop: true,
            renderer: 'svg',
            path: lottieSquare
        });
    },
    */

    modalPopupInit() {
        $(document).keyup(function (e) {
            if (global.modalPopupInOpen) {
                if (e.keyCode === 27) { // escape key
                    global.modalPopupClose()
                }
            }
        })

        $('.modal-overlay-hook').on('click', global.modalPopupOpen)
        $('#modalPopupOverlay .overlay-x').on('click', global.modalPopupClose)
    },
    modalPopupOpen() {
        $('body').addClass('modal-open')
        let tid = $(this).attr('tid')
        let meta = $('#' + tid).html()
        $('#modalPopupOverlay .inner1a').html(meta);
        gsap.fromTo($('#modalPopupOverlay'), 0.35,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: global.modalPopupOpen2,
            }
        )
        $('#modalPopupOverlay').css('visibility', 'visible')
        $('#modalPopupOverlay .modal-button-hook').removeClass('accepted')
        $('#modalPopupOverlay .modal-button-hook').addClass('not-accepted')
        $('#modalPopupOverlay .terms-checkbox').prop('checked', false);
        $('#modalPopupOverlay .terms-checkbox').on('click', global.modalTermsCheck)
    },
    modalPopupOpen2() {
        global.modalPopupInOpen = true
    },
    modalTermsCheck() {
        if ($('#modalPopupOverlay .terms-checkbox').prop('checked')) {
            $('#modalPopupOverlay .modal-button-hook').addClass('accepted')
            $('#modalPopupOverlay .modal-button-hook').removeClass('not-accepted')
        } else {
            $('#modalPopupOverlay .modal-button-hook').addClass('not-accepted')
            $('#modalPopupOverlay .modal-button-hook').removeClass('accepted')

        }
    },
    modalPopupClose() {
        $('body').removeClass('modal-open')
        gsap.to($('#modalPopupOverlay'), 0.35,
            {
                opacity: 0,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: global.modalPopupClose2,
            }
        )
    },
    modalPopupClose2() {
        $('#modalPopupOverlay .inner1a').html('');
        $('#modalPopupOverlay').css('visibility', 'hidden')
        global.modalPopupInOpen = false
    },


    initShowSticky: function () {
        if ($('body').hasClass('page-show')) {
            siteCommon.scOpenTopOfPage(true)
        }

        global.scrollContent = $('#content');
        global.stickyOuter = $('.poster-sticky-outer');
        global.stickySizer = $('.poster-sticky-sizer');
        global.window = $(window)


        $(window).on( "resize", global.updateStickyResize);
        $(window).on('scroll', global.updateStickyPosition)
        global.updateStickyPosition()
        global.updateStickyResize()
        setTimeout(global.updateStickyResize, 100)
        setTimeout(global.updateStickyResize, 500)
        setTimeout(global.updateStickyResize, 1000)
    },

    updateStickyResize: function() {
        let theh = $('.poster-sticky-sizer').height() + 40
        let thew = $('.lr-common .col1').width() - 40
        $('.col1-height-hook').height(theh)
        $('.poster-sticky').width(thew)
    },

    /*
    old logic that mirrors sticky
    updateStickyPosition: function(e) {

        const currentScrollY = global.window.scrollTop();
        const contentTop = global.scrollContent.offset().top - global.window.scrollTop();
        const contentBottom = global.scrollContent.offset().top + global.scrollContent.outerHeight();
        const stickyOuterHeight = global.stickyOuter.outerHeight();
        const viewportHeight = global.window.height();
        const maxStickyTop = viewportHeight - stickyOuterHeight;

        if (contentTop <= -60) {
            if ((contentBottom - currentScrollY - 10) <= stickyOuterHeight) {
                // If stickyOuter should shift up to align with contentBottom
                global.stickyOuter.css({
                    'position': 'absolute',
                    'top': `${contentBottom - global.scrollContent.offset().top - stickyOuterHeight}px`
                });
            } else {
                // Stick to fixed position within the viewport
                global.stickyOuter.css({
                    'position': 'fixed',
                    'top': '10px'
                });
            }
        } else {
            global.stickyOuter.css({
                'position': 'absolute',
                'top': '70px'
            });
        }
    }

     */

    // new peek a boo logic
    updateStickyPosition: function(e) {

        const currentScrollY = global.window.scrollTop();
        const contentTop = global.scrollContent.offset().top - global.window.scrollTop();
        const contentBottom = global.scrollContent.offset().top + global.scrollContent.outerHeight();
        const stickyOuterHeight = global.stickySizer.outerHeight();
        const viewportHeight = global.window.height();
        const maxStickyTop = viewportHeight - stickyOuterHeight;

        if (currentScrollY > 0) {
            if ((contentBottom - currentScrollY - 220) <= stickyOuterHeight) {
                // If stickyOuter should shift up to align with contentBottom
                global.stickyOuter.css({
                    'position': 'absolute',
                    'top': `${contentBottom - global.scrollContent.offset().top - stickyOuterHeight}px`
                });
            } else {
                if (global.pageLoadContentScrollPosition === null) {
                    // Stick to fixed position within the viewport
                    global.stickyOuter.css({
                        'position': 'absolute',
                        'top': '70px'
                    });
                } else {
                    // Stick to fixed position within the viewport
                    global.stickyOuter.css({
                        'position': 'fixed',
                        'top': global.pageLoadContentScrollPosition
                    });
                }

            }
        } else {
            global.pageLoadContentScrollPosition = contentTop + 70
            global.stickyOuter.css({
                'position': 'absolute',
                'top': '60px'
            });
        }
    }

}
export default global
