//DEFAULTS
import "imports/core-js/stable";
import "imports/regenerator-runtime/runtime";

//import overlay from 'site/overlay'
import site from 'site/site'
import home from 'site/home'
import global from 'site/global'
import photoStrip from 'site/photoStrip'
import participatingShowsStrip from 'site/participatingShowsStrip'
import siteScroll from 'site/scroll'
import mobileNav from 'site/mobileNav'
import quickThumbsPrep from 'site/quickThumbsPrep'
import siteAOS from 'site/site.aos'
import quickOneSwipePrep from 'site/quickOneSwipePrep'
import mediaOverlay from 'site/mediaOverlay'
import expandables from 'site/expandables'
import lines from 'site/lines'
import specialOffers from 'site/specialOffers'

$(document).ready(function() {
    mobileNav.init()
    site.init()
    global.init()
    siteScroll.init()
    photoStrip.init()
    participatingShowsStrip.init()
    home.init()
    expandables.init()
    lines.init()
    specialOffers.init()

    setTimeout( function() {
        siteAOS.init()
        siteAOS.refreshAOS()

        mediaOverlay.init()
        quickThumbsPrep.init()
        quickOneSwipePrep.init()
    },500)
})
