import { gsap, CSSPlugin, ScrollToPlugin } from 'imports/gsap/all'
import { Power4 } from 'imports/gsap'
import Swiper from 'swiper'
import { Navigation, Grid } from 'swiper/modules'

let photoStrip = {
    init: function () {
        $(".block-photostrip .carousel").each(function () {
            let ps = new Swiper( $('.swiper', this)[0], {
                modules: [Navigation, Grid],
                init: false,
                loop: true,
                spaceBetween: 50,
                slidesPerView: 3,
                slidesPerGroup: 3,
                //slidesPerView: 'auto',
                //slideToClickedSlide: true,
                //watchSlidesVisibility: true,
                watchSlidesProgress: true,
                navigation: {
                    //nextEl: $('.btn-arrow-right', this)[0],
                    //prevEl: $('.btn-arrow-left', this)[0]
                    nextEl: $(".block-photostrip .btn-arrow-right").toArray(),
                    prevEl: $(".block-photostrip .btn-arrow-left").toArray()
                },
                breakpoints: {
                    0: {
                        spaceBetween: 20,
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        grid: {
                            rows: 2,
                        },
                    },
                    900: {
                        spaceBetween: 50,
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        grid: {
                            rows: 1,
                        },
                    }
                }
            })
            ps.init()
        })
    }
}
export default photoStrip
