import gsap, { Power4 } from 'imports/gsap';
import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';

let mediaOverlay = {
    swiper: null,
    mediaOverlayIsOpen: false,
    youtubeId: '',
    ytArray: {},
    yt: null,
    ytInterval: null,
    ytOverlayIsReady: false,
    initialSlide: 0,

    init: function () {
        $('.mediaOverlay-hook').on('click', mediaOverlay.doMediaOverlayHandle);
        $('#mediaOverlay .overlay-x').on('click', mediaOverlay.doMediaOverlayClose);

        $(document).keyup(function (e) {
            if (mediaOverlay.mediaOverlayIsOpen) {
                if (e.keyCode === 27) { // Escape key
                    mediaOverlay.doMediaOverlayClose();
                }
            }
        });

        mediaOverlay.ytArray = {};

        // Bind resizeAllImages to the window resize event
        $(window).on('resize', mediaOverlay.resizeAllImagesFunction);

        $(document).ready(mediaOverlay.resizeAllImages);
    },

    resizeAllImagesFunction() {
        mediaOverlay.resizeAllImages()
        setTimeout(mediaOverlay.resizeAllImages,100)
    },

    resizeAllImages: function () {
        // Iterate through all `.imghook` containers
        $('.imghook').each(function () {
            const $imghook = $(this);
            const $img = $imghook.find('img');

            if ($img.length === 0) return; // Skip if no image

            // Get the dimensions of `.imghook` container
            const imghookWidth = $imghook.width();
            const imghookHeight = $imghook.height();

            // Get the aspect ratio of the image
            const imgWidth = $img.attr('data-image-width')
            const imgHeight = $img.attr('data-image-height')
            const imgAspectRatio = Number($img.attr('data-aspect-ratio'))
            // Set the image to 100% width initially
            let newh = imghookWidth / imgAspectRatio
            $img.css({
                width: imghookWidth,
                height: newh
            });
            if (newh > imghookHeight) {
                $img.css({
                    width: imghookHeight * imgAspectRatio,
                    height: imghookHeight
                });
            }
        });
    },

    onSlideChange(e, a, b) {
        mediaOverlay.ytVideoStop();
        let ytHolder = $('#mediaOverlayInner .swiper-slide-' + e.realIndex);
        if (ytHolder) {
            let ytHolderId = ytHolder.attr('data-random-id');
            let ytHolderYoutubeId = ytHolder.attr('data-youtube-id');
            mediaOverlay.ytVideoPlay(ytHolderId, ytHolderYoutubeId);
        }
        setTimeout(mediaOverlay.resizeAllImages,100)
    },

    onSlideInit(e) {
        mediaOverlay.initYT();
        mediaOverlay.doMediaOverlayOpenReveal(mediaOverlay.initialSlide);
        mediaOverlay.resizeAllImages()
    },

    initYT() {
        if (!youTubeIsReady) {
            mediaOverlay.ytInterval = setTimeout(mediaOverlay.initYT, 500);
        } else {
            mediaOverlay.initYT2();
        }
    },

    initYT2() {
        clearTimeout(mediaOverlay.ytInterval);

        $('#mediaOverlayInner .swiper-wrapper .swiper-slide').each(function () {
            let ytHolderId = 'youtube-' + $(this).attr('data-random-id');
            let ytHolderYoutubeId = $(this).attr('data-youtube-id');
            if (ytHolderId && ytHolderYoutubeId) {
                $('.ytholder', this).attr('id', ytHolderId);
            }
        });
    },

    doMediaOverlayHandle: function () {
        let mediaId = $(this).attr('data-media-id');
        let thtml = $('#swiper-popup-meta-' + mediaId).html();
        $('#swiper-super-outer').html(thtml);
        if (thtml === undefined || thtml === null || thtml.trim() === "") {
            // do nothing
        } else {
            mediaOverlay.mediaOverlayIsOpen = true;
            mediaOverlay.swiper = new Swiper("#swiper-super-outer .swiper", {
                modules: [Navigation, Pagination, Keyboard],
                init: false,
                loop: true,
                initialSlide: 0,
                spaceBetween: 20,
                slidesPerView: 1,
                slidesPerGroup: 1,
                slideToClickedSlide: true,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: "#swiper-super-outer .swiper-button-next",
                    prevEl: "#swiper-super-outer .swiper-button-prev",
                },
                pagination: {
                    enabled: true,
                    clickable: true,
                    el: "#media-dots .dots",
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
            });
            mediaOverlay.initialSlide = $(this).attr('data-index');
            mediaOverlay.swiper.on('slideChangeTransitionEnd', mediaOverlay.onSlideChange);
            mediaOverlay.swiper.on('afterInit', mediaOverlay.onSlideInit);
            mediaOverlay.swiper.init();
        }
    },

    doMediaOverlayOpenReveal: function (initialSlide = 0) {
        $('body').addClass('modal-open')
        mediaOverlay.swiper.slideTo(initialSlide);

        let ytHolder = $('#mediaOverlayInner .swiper-slide-' + initialSlide);
        if (ytHolder) {
            let ytHolderId = ytHolder.attr('data-random-id');
            let ytHolderYoutubeId = ytHolder.attr('data-youtube-id');
            mediaOverlay.ytVideoPlay(ytHolderId, ytHolderYoutubeId);
        }

        gsap.fromTo($('#mediaOverlay'), 1,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: mediaOverlay.doMediaOverlayOpenReveal2,
            }
        );
        $('#mediaOverlay').css('visibility', 'visible');
    },

    doMediaOverlayOpenReveal2: function () {},

    doMediaOverlayClose: function () {
        $('body').removeClass('modal-open')
        gsap.fromTo($('#mediaOverlay'), 0.5,
            {
                opacity: 1,
            },
            {
                opacity: 0,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: mediaOverlay.doMediaOverlayClose2,
            }
        );
        mediaOverlay.mediaOverlayIsOpen = false;
    },

    doMediaOverlayClose2: function () {
        $('#mediaOverlay').css('visibility', 'hidden');
        mediaOverlay.ytVideoStop();
    },

    ytVideoPlay(ytHolderId, ytHolderYoutubeId) {
        ytHolderId = 'youtube-' + ytHolderId;
        try {
            if (!mediaOverlay.ytArray[ytHolderId]) {
                mediaOverlay.ytArray[ytHolderId] = new YT.Player(ytHolderId, {
                    height: '390',
                    width: '640',
                    videoId: ytHolderYoutubeId,
                    host: 'https://www.youtube-nocookie.com',
                    playerVars: {
                        'autoplay': 1,
                        'controls': 1,
                        'playsinline': 1,
                        'modestbranding': 1,
                        'rel': 0,
                    },
                });
            } else {
                mediaOverlay.ytArray[ytHolderId].playVideo();
            }
        } catch (e) {
            console.error('Video error:', e);
        }
    },

    ytVideoStop() {
        Object.values(mediaOverlay.ytArray).forEach(player => {
            try {
                if (player && typeof player.stopVideo === 'function') {
                    player.stopVideo();
                }
            } catch (e) {
                // Handle errors if needed
            }
        });
    },
};

export default mediaOverlay;
