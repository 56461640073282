import siteCommon from 'site/site-common'
let siteScroll = {
    init:function () {
        ////////////////////////////
        $(window).on("scroll",siteScroll.scroll);
        $(window).on( "resize", siteScroll.scroll);

        //siteScroll.setHeaderPusher(false)
        siteScroll.scroll();

    },//init

    //////////////////////////////////////////////////////////////////////////////////////////
    scroll:function() {
        var scrollTop = $(window).scrollTop();
        var headershrink_h = 0;
        if (scrollTop>headershrink_h) {
            $('body').addClass('miniheader');
        } else {
            $('body').removeClass('miniheader');
        }
        //siteScroll.setHeaderPusher(true)
    },
}
export default siteScroll
