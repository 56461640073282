import siteCommon from 'site/site-common'
import gsap, { Power3 } from 'imports/gsap'
let mobileNav = {
    init:function () {
        var self = this;
        $('#mobile-nav-hamburger .hamburger').click(self.doHamburerToggle);
        $('#mobile-nav-overlay .navitem').click(self.overlayHide);
        $('#mobile-nav-overlay .mobile-nav-expander').click(self.subNavToggle);
        mobileNav.overlayHide();
    },//init

    doHamburerToggle:function(){
        if ($(this).hasClass('is-active')) {
            mobileNav.overlayHide();
        } else {
            mobileNav.overlayShow();
        }
    },//doHamburerToggle

    subNavToggle:function(e) {
        if ($(this).hasClass('open')) {
            mobileNav.subNavClose();
        } else {
            mobileNav.subNavOpen(this);
        }
    },
    subNavOpen:function(t) {
        mobileNav.subNavClose();
        $(t).addClass('open');
    },
    subNavClose:function() {
        $('#mobile-nav-overlay .mobile-nav-expander').removeClass('open');
    },
    overlayShow:function() {
        siteCommon.scOpenTopOfPage()
        $('#mobile-nav-hamburger .hamburger').addClass('is-active');
        $('#mobile-nav-overlay').removeClass('hidden');
        $('#mobile-nav-overlay-outer').removeClass('hidden');

        let tl = gsap.timeline({})
        tl.fromTo("#mobile-nav-overlay-outer", 1.4,
            {height:0},
            {height:'auto', ease:Power3.easeOut},
        );
        tl.staggerFromTo("#mobile-nav-overlay .li-1", 0.5,
            {opacity:0, x:0 },
            {opacity:1, x:0, ease:Power3.easeOut},
            0.06,//stagger
            "-=1.2"
        );
        tl.staggerFromTo("#mobile-nav-overlay .mobile-reveal", 0.5,
            {opacity:0, x:0 },
            {opacity:1, x:0, ease:Power3.easeOut},
            0.06,//stagger,
            "-=0.8"
        );
    },
    overlayHide:function() {
        $('#mobile-nav-hamburger .hamburger').removeClass('is-active');
        $('#mobile-nav-overlay').addClass('hidden');
        $('#mobile-nav-overlay-outer').addClass('hidden');

    }

}; //obj
export default mobileNav
