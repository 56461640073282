let lines = {
    rotationData: {}, // Store rotation for each line
    init() {
        $(".animated-line").each(function (index) {
            if (!$(this).data("line-id")) {
                $(this).attr("data-line-id", `line-${index}`);
            }
        });
        lines.scroll()
        $(".animated-line").css('visibility', 'visible')

        $(window).on("scroll", lines.scroll);
    },
    scroll() {
        let scrollTop = $(window).scrollTop(); // Current scroll position
        let windowHeight = $(window).height(); // Viewport height

        $(".animated-line").each(function () {
            let $this = $(this);
            let $al = $this.find(".al"); // Target the .al element within the .animated-line
            let offsetTop = $this.offset().top; // Element's top position
            let elementHeight = $this.outerHeight(); // Element's height

            // Calculate the scroll progress for the element
            let elementBottom = offsetTop + elementHeight;
            let visibleProgress =
                (scrollTop + windowHeight - offsetTop) / (elementHeight + windowHeight);

            // Clamp visibleProgress between 0 and 1
            visibleProgress = Math.max(0, Math.min(1, visibleProgress));

            // Calculate the rotation based on visibleProgress
            let maxRotation = 45; // Maximum rotation in degrees
            let rotation = (visibleProgress - 0.5) * maxRotation; // Adjust range to -maxRotation/2 to maxRotation/2

            // Reverse rotation if the element has the 'reverse' class
            if ($this.hasClass("reverse")) {
                rotation *= -1;
            }

            $al.css("transform", `rotate(${rotation}deg)`); // Apply rotation to the .al element
        });

        lines.lastScroll = scrollTop; // Update last scroll position
    }
};

export default lines;
