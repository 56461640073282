import siteCommon from 'site/site-common';
import AOS from 'imports/aos'
let specialOffers = {
    init: function () {
        const $filterDropdown = $('#special-offers-filter');
        if ($filterDropdown.length) {
            $filterDropdown.on('change', specialOffers.onSelect);
        }
    },

    onSelect: function () {
        const value = $(this).val();
        const $items = $('.block-special-offers .grid .item');

        $items.each(function () {
            const itemFilter = $(this).data('filter');
            if (!value || itemFilter === value) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
        AOS.refreshHard()
    },
};

export default specialOffers;
