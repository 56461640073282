import { gsap, CSSPlugin, ScrollToPlugin } from 'imports/gsap/all'
import { Power4 } from 'imports/gsap'
import Swiper from 'swiper'
import { Navigation, Grid } from 'swiper/modules'

let participatingShowsStrip = {
    length: 0,
    html: null,
    init: function () {
        $(window).on("resize", participatingShowsStrip.init2);
        participatingShowsStrip.init2()
    },

    init2: function () {
        $(".block-participating-shows .carousel").each(function () {
            if (participatingShowsStrip.html === null) {
                participatingShowsStrip.length =  $(".swiper-slide", this).length

                let totalCount = participatingShowsStrip.length
                let initialSlide = Math.floor(Math.random() * totalCount)


                // rearrange html - initialSlide in swiper is only good if it loops
                // the 2x2 grid is unloopable
                // thus, we need to actually rearrange the JS to start at a ranom spot at index 0
                // otherwise the pagination feels funny
                let thtml = ''
                let i = 0
                $(".swiper-slide", this).each(function () {
                    if (i >= initialSlide) {
                        thtml += $(this).prop('outerHTML')
                    }
                    i++
                })
                i = 0
                $(".swiper-slide", this).each(function () {
                    if (i<initialSlide) {
                        thtml += $(this).prop('outerHTML')
                    }
                    i++
                })
                participatingShowsStrip.html = thtml
            }

            $(".swiper-wrapper", this).html(participatingShowsStrip.html)




            let promoSwiper = new Swiper( $('.swiper', this)[0], {
                modules: [Navigation, Grid],
                init: false,
                loop: true,
                spaceBetween: 50,
                slidesPerView: 4,
                slidesPerGroup: 4,
                //slidesPerView: 'auto',
                //slideToClickedSlide: true,
                //watchSlidesVisibility: true,
                watchSlidesProgress: true,
                //initialSlide: initialSlide,
                navigation: {
                    //nextEl: $('.btn-arrow-right', this)[0],
                    //prevEl: $('.btn-arrow-left', this)[0]
                    nextEl: $(".block-participating-shows .btn-arrow-right").toArray(),
                    prevEl: $(".block-participating-shows .btn-arrow-left").toArray()
                },
                // pagination: {
                //     enabled:true,
                //     clickable: true,
                //     el: "#quick-one-dots .dots",
                // },
                breakpoints: {
                    0: {
                        loop: false,
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 20,
                        grid: {
                            rows: 2,
                            fill: 'row'
                        },
                    },
                    900: {
                        loop: true,
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 50,
                        grid: {
                            rows: 1,
                        },
                    },
                    1200: {
                        loop: true,
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 50,
                        grid: {
                            rows: 1,
                        },
                    }
                }
            })
            promoSwiper.on('transitionEnd', function (e) {
                participatingShowsStrip.redoCount(e)
            });

            promoSwiper.on('init', function (e) {
                participatingShowsStrip.redoCount(e)
                // let countPerFrame = e.visibleSlidesIndexes.length
                // let totalSlides = participatingShowsStrip.length;
                // let totalFrames = Math.ceil(totalSlides / countPerFrame)
                // let y = Math.ceil(totalSlides / totalFrames)
                // $('.participating-shows-count').text(`1 of ${y}`);
            })
            promoSwiper.init()
        })
    },
    redoCount(e) {
        let docWidth = $(window).width();

        let countPerFrame = e.visibleSlidesIndexes.length
        let currentIndex = e.realIndex + 1;
        let totalSlides = participatingShowsStrip.length;
        let totalFrames = Math.ceil(totalSlides / countPerFrame)
        //let progress = ( e.progress * countPerFrame) + 1;

        //console.log('currentIndex', totalSlides, totalFrames, countPerFrame, e)
        let x
        let y
        if (docWidth < 900) {
            x = Math.ceil(e.activeIndex/2)+1
            y = Math.ceil( totalSlides / 4) // 4 per frame
        } else {
            x = Math.ceil(currentIndex / countPerFrame)
            y = Math.ceil(totalSlides / countPerFrame)
        }
        // Update the counter
        $('.participating-shows-count').text(`${x} of ${y}`);
    }
}
export default participatingShowsStrip
